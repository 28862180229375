<template lang="pug">
div(style="margin-top: -35px")
  .bg-primary.text-white.hero1.row(ref="toolbar", v-scroll="scrolled")
    .lt-md.hero2a
    .col
      .row
        .col-12
          Breadcrumbs(:title="$t('academy.title')")
      .row.items-center.full-height
        .col-12
          div.q-pb-xl.q-ma-dynamic
            h1(style="margin-top: -0.5em") {{ $t("academy.title") }}
            .text-body1 {{ $t("learn-the-essential-skills-and-get-the-resources-to-bring-your-climate-innovations-to-life") }}
            .text-body1
              ul
                li {{ $t("become-a-certified-ifrc-limitless-innovator") }}
                li {{ $t("opportunity-to-get-funding-to-develop-and-test-your-idea") }}
                li {{ $t("learn-from-innovation-experts-and-peer-mentors") }}
                li {{ $t("join-a-global-network-of-innovators-0") }}
                li {{ $t("get-a-chance-to-present-to-the-global-leaders-of-the-ifrc-network") }}
                li {{ $t("access-professional-training-on-climate-innovation-and-leadership") }}

            q-btn.q-mt-xl.on-left(
              align="between",
              style="width: 300px; max-width: 74vw",
              size="lg",
              to="/academy/resources",
              outline,
              :icon-right="matNorthEast",
              color="white",
              no-caps
            ) Application resources
            q-btn.q-mt-xl.bg-white(
              align="between",
              style="width: 300px; max-width: 74vw",
              size="lg",
              @click="$emit('startLogin')",
              flat,
              :icon-right="matNorthEast",
              color="secondary",
              no-caps,
              v-if="isOpen"
            ) {{ $t("view_profile") }}
            //- .text-body1.q-mt-md(v-if="isOpen") {{$t('applications-close',[closeDate])}}
            .q-my-md(v-if="isOpen") {{ $t("didnt-receive-your-one-time-link-email-contact-us-at") }} &nbsp;
              a.text-bold.text-white(
                href="mailto:support.limitless@solferinoacademy.com"
              ) support.limitless@solferinoacademy.com
            .text-body1(v-if="!isOpen") {{ $t("limitless-academy-is-launching-in-early-2024") }}
    .col-6.gt-sm
      .hero2

  div(style="height: 50px", v-show="snap")
  q-toolbar.bg-secondary.q-pa-none(:class="{ fixme: snap }", v-if="isOpen")
    .scroll.hide-scrollbar.q-pa-dynamic
      .row.justify-left.no-wrap
        .col-auto
          q-btn(flat, dark, color="white", no-caps, to="#about") {{ $t("about") }}
        .col-auto
          q-btn(flat, dark, color="white", no-caps, to="#details") {{ $t("program-details") }}
        .col-auto
          q-btn(flat, dark, color="white", no-caps, to="#modules") {{ $t("modules") }}
        .col-auto
          q-btn(flat, dark, color="white", no-caps, to="#eligibility") {{ $t("eligibility") }}
        .col-auto
          q-btn(flat, dark, color="white", no-caps, to="#apply") {{ $t("how-to-apply") }}
        .col-auto
          q-btn(flat, dark, color="white", no-caps, to="#timeline") {{ $t("program-timeline") }}
        .col-auto
          q-btn(flat, dark, color="white", no-caps, to="#faq") {{ $t("faqs") }}
  #about.bg-white
    .row.items-center.full-height.q-my-xl
      .col-12.col-md-9
        .q-pa-dynamic
          h1 {{ $t("about-limitless-academy") }}
          .text-body1 {{ $t("the-ifrc-limitless-youth-innovation-academy-is-designed-to-be-a-safe-learning-place-for-red-cross-and-red-crescent-volunteers-and-staff-to-learn-about-innovation-approaches-meet-and-engage-other-innovators-and-test-new-ideas-with-funding-and-mentoring-support-it-is-open-to-all-youth-volunteers-and-staff-18-30-years-old-who-want-to-make-a-difference-in-their-communities-by-learning-about-innovation-and-not-only-those-with-fully-formed-ideas-and-projects") }}
  .bg-white(v-if="isOpen")
    .row
      .col-12.q-py-xl
        .q-pa-dynamic
          .text-h2.q-mb-lg {{ $t("by-joining-limitless-academy-you-will-learn-to") }}
          .row.justify-center.q-col-gutter-lg.text-center
            .col-6.col-md
              q-img(
                src="/img/pic9.webp",
                :ratio="1",
                contain,
                style="max-height: 200px"
              )
              .text-body2 {{ $t("gather-appropriate-evidence-to-understand-a-community-and-its-challenges") }}
            .col-6.col-md
              q-img(
                src="/img/pic6.webp",
                :ratio="1",
                contain,
                style="max-height: 200px"
              )
              .text-body2 {{ $t("apply-the-double-diamond-design-framework-to-support-innovations") }}
            .col-6.col-md
              q-img(
                src="/img/pic1.webp",
                :ratio="1",
                contain,
                style="max-height: 200px"
              )
              .text-body2 {{ $t("test-and-implement-your-ideas-in-your-communities") }}
            .col-6.col-md
              q-img(
                src="/img/pic10.webp",
                :ratio="1",
                contain,
                style="max-height: 200px"
              )
              .text-body2 {{ $t("effectively-communicate-your-ideas-to-different-stakeholders") }}

  #details.bg-secondary.text-white.q-pb-lg(v-if="isOpen")
    .row.items-center.full-height
      .col-12
        .q-pa-dynamic.q-py-xl
          h1 {{ $t("whats-inside-the-program") }}
          .text-body1 {{ $t("up-to-1-000-teams-or-individuals-will-be-selected-to-receive-at-least-500-chf-seed-funding-and-special-mentoring-support-to-develop-and-test-their-ideas") }}
          .row.q-col-gutter-xl.q-mt-sm
            .col-12.col-md-6
              .bg-accent.text-black.q-pa-md
                .text-h2 {{ $t("theory") }} &nbsp;
                  sup.text-overline NEW
                .text-body1 {{ $t("whatsapp-masterclasses-on-innovation") }}
                //- .text-caption {{ $t('NEW') }}
              .text-white.text-body1.q-pr-md.q-pa-md
                .text-body1 {{ $t("for-2-weeks-you-will-join-a-series-of-whatsapp-based-classes-facilitated-by-innovation-experts-the-classes-are-designed-to-build-your-fundamental-knowledge-on-innovation-and-design-tools-the-classes-include") }}
                ul
                  li {{ $t("group-reflections-and-fun-activities-0") }}
                  li {{ $t("real-life-case-studies-0") }}
                  li {{ $t("live-chats-with-innovation-experts-0") }}
                  li {{ $t("peer-support-on-demand") }}
                  li {{ $t("special-access-to-innovation-conferences-and-speakers") }}
              .text-body1.text-bold {{ $t("the-program-requires-up-to-30-minutes-of-your-time-each-day-during-the-2-week-training-to-engage-in-individual-and-small-group-activities") }}
              .q-mt-md
                span.text-body1.text-bold.bg-white.text-secondary.q-pa-sm {{ localiseDateRangeBlur(2, "01/05/2024") }}

            .col-12.col-md-6
              .bg-accent.text-black.q-pa-md
                .text-h2 {{ $t("practice") }}
                .text-body1 {{ $t("hands-on-ideation-and-pilot-testing-experience") }}
              .text-white.text-body1.q-pa-md
                .text-body1 {{ $t("academy.money") }}
              .q-pa-md
                .bg-white.text-primary
                  .q-pa-md 
                    |
                    .row.items-center.q-col-gutter-md
                      .col-auto
                        q-icon(name="un:flood", size="24px")
                      .col
                        .text-body1.text-bold Sponsored Award: Water
                    .text-body1.q-mt-md Water is vital in the humanitarian context.
                    .text-body1 Some Limitless funding is set-aside for creative solutions to address water-related challenges.
                    .row.justify-between.q-mt-md
                      .col-auto.self-center
                        img(
                          src="/img/partners/nl.webp",
                          alt="NL Red Cross logo",
                          height="48px"
                        )

                      .col-auto
                        .text-right
                          q-btn.bg-primary(
                            size="lg",
                            flat,
                            color="white",
                            no-caps,
                            :icon-right="matNorthEast",
                            align="between",
                            style="min-width: 200px",
                            href="https://solferinoacademy.com/ifrc-limitless-youth-innovation-academy-water-prize/",
                            target="_BLANK"
                          ) Find out more

          //- .row.q-col-gutter-xl.q-mb-lg
          //-   .row.q-col-gutter-xl
          //-     .col-12
          //-       .q-pa-md
          //-         .text-white.text-body1 
          //-           strong {{ $t('the-program-requires-up-to-30-minutes-of-your-time-each-day-during-the-2-week-training-to-engage-in-individual-and-small-group-activities') }}

  .bg-secondary(v-if="isOpen")
    //- .slantline

  #modules.bg-secondary(v-if="isOpen")
    .row
      .col-12.col-md-12
        .row.q-mb-xl.text-white
          .col-12
            Topics(
              :data="messages.academy.topics",
              :color="'white'",
              :selected="'accent'"
            )

  #eligibility.bg-white
    .row.items-center.q-mb-xl(style="position: relative")
      .lt-md.hero4a
      .col-6.gt-sm 
        |
        .hero4
      .col-12.col-md-6.q-py-xl
        .q-pa-dynamic
          h1 {{ $t("who-is-limitless-academy-for") }}
          ul.text-body1
            li {{ $t("all-youth-volunteers-and-staff-18-30-years-old-from-the-red-cross-and-red-crescent-network-and-national-societies") }}
            li {{ $t("community-members-and-community-based-organisations-partnering-with-national-societies-to-address-the-climate-and-environmental-crises") }}

      .hero-border

  #apply.bg-lilac.q-py-xl(style="margin-top: -30px", v-if="isOpen")
    .row.items-center.q-mb-xl.text-secondary(style="position: relative")
      .col-12.q-py-xl
        .q-ma-dynamic
          .text-h1 {{ $t("how-to-apply-to-join-limitless-academy") }}
          .text-body1.q-mt-lg {{ $t("to-apply-sign-up-to-the-limitless-submission-portal-using-your-email-address-and-upload-a-short-video-answering-the-following-questions") }}
          //- .row.q-col-gutter-lg.text-white.justify-center.text-body1.q-mt-sm
          //-   .col-auto
          //-     .q-pa-lg.bg-secondary.full-height {{ $t('phases.phase_1.vid1.title') }}

          //- .text-body1.q-mt-lg.text-secondary {{ $t('respond-to-the-following-prompts') }}
          .row.q-col-gutter-lg.text-white.items-stretch.text-body1.q-mt-sm.q-mb-sm.justify-center
            .col-12.col-md-4.col-md(
              v-for="(n, index) in numVideos",
              :key="index"
            )
              .q-pa-md.bg-secondary.full-height
                .text-body1 {{ $t(`phases.${config.current_phase}.vid${index + 1}.title`) }}
                .text-body2 {{ $t(`phases.${config.current_phase}.vid${index + 1}.description`) }}

          .row.text-body1.q-mt-lg
            .col-12 {{ $t("you-can-submit-your-video-in-one-of-these-languages") }}
            .col-12.q-pt-lg
              q-chip.text-white(
                size="lg",
                color="primary",
                v-for="lang in languages",
                :key="lang.value"
              ) {{ lang.label }}

          .row.q-mt-xl
          //-   .col-12.q-my-lg
          //-     .text-h1 {{ $t('important-before-applying') }}

          .row.justify-center
            .col-11.col-md-8
              q-video(
                :ratio="16 / 9",
                src="https://www.youtube.com/embed/6zqFNN1fu4c"
              )
              .row.q-mt-lg.q-col-gutter-lg.justify-between
                .col-md-6.col-12
                  .bg-white.q-pa-md.fit
                    .column.fit
                      .col
                        .text-body1.q-mb-lg.text-secondary.text-bold {{ $t("quick-steps-to-apply-the-three-questions") }}
                      .col-auto.text-right
                        q-btn-dropdown.bg-secondary(
                          size="lg",
                          flat,
                          color="white",
                          no-caps,
                          align="between",
                          :label="$t('download-pdf')"
                        )
                          q-list
                            q-item(
                              clickable,
                              :href="file.url",
                              :key="file.name",
                              v-for="file of getStepsFiles()",
                              target="_BLANK"
                            ) {{ file.name }}

                      //- .col-auto
                      //-   q-btn.bg-secondary(:href="getStepsVideoUrl()" size="lg" flat color="white" no-caps :icon-right="matNorthEast" align="between") {{ $t('watch-video') }}
                .col-md-6.col-12
                  .bg-white.q-pa-md.fit
                    .column.fit
                      .col
                        .text-body1.q-mb-lg.text-secondary.text-bold {{ $t("guidelines-for-a-powerful-video") }}
                      .col-auto.text-right
                        q-btn-dropdown.bg-secondary(
                          size="lg",
                          flat,
                          color="white",
                          no-caps,
                          align="between",
                          :label="$t('download-pdf')"
                        )
                          q-list
                            q-item(
                              clickable,
                              :href="file.url",
                              v-for="file of getGuideFiles()",
                              :key="file.url",
                              target="_BLANK"
                            ) {{ file.name }}

                  //- .col-auto
                  //-   q-btn.bg-secondary(:href="getGuideVideoUrl()" size="lg" flat color="white" no-caps :icon-right="matNorthEast" align="between") {{ $t('watch-video') }}

  #timeline.q-ma-dynamic(
    style="margin-top: 150px; margin-bottom: 100px",
    v-if="isOpen"
  )
    .row.items-top
      .col-12.col-md-6
        .text-h1 {{ $t("program-timeline") }}
        q-stepper(
          vertical,
          flat,
          dense,
          value="-1",
          done-icon="none",
          header-class="myheader"
        )
          q-step(
            :title="$t('applications-close', [''])",
            name="0",
            :caption="localiseDateSpecific('21/04/2024')",
            done
          )
          q-step(
            :title="$t('whatsapp-masterclasses')",
            :caption="localiseDate('01/05/2024')",
            name="1",
            done
          )
          q-step(
            :title="$t('phases.phase_3.title')",
            :caption="localiseDateRange('01/05/2024', '01/08/2024')",
            name="1",
            done
          )
          q-step(
            :title="$t('phases.phase_4.title')",
            :caption="localiseDateRange('01/09/2024', '01/11/2024')",
            name="1",
            done
          )
          q-step(
            :title="$t('cultivate-your-climate-idea')",
            :caption="localiseDateRange('01/12/2024', '01/07/2025')",
            name="2",
            done
          )

        q-btn.q-mt-xl.bg-primary(
          align="between",
          style="width: 300px; max-width: 74vw",
          size="lg",
          @click="$emit('startLogin')",
          flat,
          :icon-right="matNorthEast",
          color="white",
          no-caps
        ) {{ $t("apply-now") }}

      .col-6.gt-sm.self-center
        q-img(
          src="/img/clock2.webp",
          :ratio="1",
          contain,
          style="max-height: 400px"
        )

  FAQ#faq(:data="messages.academy.faq", :bg="'white'", v-if="isOpen")
</template>

<script>
import Signup from "../components/Signup.vue";
import FAQ from "../components/FAQ.vue";
import Topics from "../components/Topics.vue";
import { debounce } from "quasar";
import { mapActions, mapState } from "vuex";
import { find, includes, map } from "lodash";
import { DateTime } from "luxon";
import Language from "@/mixins/Language";
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
  components: { Signup, FAQ, Topics, Breadcrumbs },
  async mounted() {
    await Promise.all([this.initConfig()]);
  },
  mixins: [Language],
  data: function () {
    return {
      snap: false,
    };
  },
  computed: {
    numVideos() {
      if (this.config && this.config.phases) {
        let phase = find(this.config.phases, {
          code: this.config.current_phase,
        });
        return parseInt(phase.noOfVideos);
      } else {
        return 0;
      }
    },
    ...mapState(["config"]),
    isOpen() {
      return process.env.VUE_APP_APPLICATIONS_OPEN === "TRUE";
    },
    languages() {
      if (this.config) {
        // const ph = find(this.config.phases,{code:this.config.current_phase});

        let lngs = [];
        for (let l of this.config.target_language)
          lngs.push({
            label: this.codeToLang(l),
            value: l,
          });
        return lngs;
      } else return [];
    },
    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
    closeDate() {
      //find current_phase close date:

      // console.log(this.config.current_phase);

      if (this.config) {
        const ph = find(this.config.phases, {
          code: this.config.current_phase,
        });
        // console.log(DateTime.fromSeconds(ph.closesAt._seconds));

        return DateTime.fromSeconds(ph.closesAt._seconds).toLocaleString(
          DateTime.DATE_FULL
        );
      } else {
        return "...";
      }
    },
  },
  methods: {
    getStepsFiles() {
      if (this.config) {
        let langs = [
          "en",
          "es",
          "ar",
          "fr",
          "zh",
          "id",
          "ja",
          "pl",
          "bn",
          "hi",
          "it",
          "ko",
          "ru",
          "pt",
          "vi",
        ];
        return map(langs, (l) => {
          return {
            url: `${this.config.cloudfronturl}/assets/${this.config.current_phase}/steps/${l}.pdf`,
            name: this.codeToLang(l),
          };
        });
      } else return [];
    },
    getStepsVideoUrl() {
      if (this.config) {
        let lang = this.$i18n.locale;
        if (!includes(["es", "ar", "fr"], lang)) lang = "en";
        return `${this.config.cloudfronturl}/assets/${this.config.current_phase}/steps/${lang}.mp4`;
      } else {
        return "";
      }
    },
    getGuideFiles() {
      if (this.config) {
        let langs = [
          "en",
          "es",
          "ar",
          "fr",
          "zh",
          "id",
          "ja",
          "pl",
          "bn",
          "de",
          "hi",
          "it",
          "ko",
          "ru",
          "pt",
          "tr",
          "vi",
        ];
        return map(langs, (l) => {
          return {
            url: `${this.config.cloudfronturl}/assets/${this.config.current_phase}/guide/${l}.pdf`,
            name: this.codeToLang(l),
          };
        });
      } else return [];
    },
    // getGuidePdfUrl() {
    //   if (this.config) {
    //     let lang = this.$i18n.locale;
    //     if (!includes(["es", "ar", "fr"], lang)) lang = "en";
    //     return `${this.config.cloudfronturl}/assets/${this.config.current_phase}/guide/${lang}.pdf`;
    //   } else {
    //     return "";
    //   }
    // },
    getGuideVideoUrl() {
      if (this.config) {
        let lang = this.$i18n.locale;
        if (!includes(["es", "ar", "fr"], lang)) lang = "en";
        return `${this.config.cloudfronturl}/assets/${this.config.current_phase}/guide/${lang}.mp4`;
      } else {
        return "";
      }
    },

    localiseDate(str) {
      let dt = DateTime.fromFormat(str, "dd/MM/yyyy");
      return dt.toLocaleString({ month: "long", year: "numeric" });
    },
    localiseDateSpecific(str) {
      let dt = DateTime.fromFormat(str, "dd/MM/yyyy");
      return dt.toLocaleString({
        month: "long",
        year: "numeric",
        day: "numeric",
      });
    },
    localiseDateRangeBlur(weeks, date) {
      let dt1 = DateTime.fromFormat(date, "dd/MM/yyyy");

      return `${weeks} weeks in ${dt1.toLocaleString({
        month: "long",
        year: "numeric",
      })}`;
    },
    localiseDateRange(str1, str2, long = false) {
      let dt1 = DateTime.fromFormat(str1, "dd/MM/yyyy");
      let dt2 = DateTime.fromFormat(str2, "dd/MM/yyyy");

      if (!long) {
        let yy = {
          month: "long",
        };
        // console.log(dt1.year);
        // console.log(dt2.year);
        if (dt1.year != dt2.year) yy.year = "numeric";

        return `${dt1.toLocaleString(yy)} - ${dt2.toLocaleString({
          month: "long",
          year: "numeric",
        })}`;
      } else {
        return `${dt1.toLocaleString({
          day: "2-digit",
          month: "long",
        })} - ${dt2.toLocaleString({
          day: "2-digit",
          month: "long",
          year: "numeric",
        })}`;
      }
    },

    scrolled: debounce(function () {
      // console.log(this.$refs.toolbar.getBoundingClientRect());
      this.snap = this.$refs.toolbar.getBoundingClientRect().bottom < 70;
    }, 10),
    ...mapActions(["initConfig"]),
  },
};
</script>

<style src="@platyplus/humanitarian-icons/dist/icons.css">
/* global styles */
</style>

<style lang="scss">
@import "@/styles/quasar.variables.scss";
.q-stepper__dot {
  // width: 8px !important;
  min-width: inherit !important;
  width: 16px !important;
  height: 16px !important;
  color: black;
  border-radius: 0 !important;
}

.q-stepper__dot:after,
.q-stepper__dot:before {
  background-color: $accent !important;
  width: 4px !important;
  transform: translateX(-2px);
}

.q-stepper__title {
  font-size: 1.4rem !important;
  line-height: 1.7rem !important;
}

.q-stepper__caption {
  color: $secondary;
  font-size: 1rem !important;
  line-height: 1.6rem !important;
}
</style>
<style lang="scss" scoped>
@import "@/styles/quasar.variables.scss";

.hero1 {
  // min-height: 56vw;
  min-height: 800px;
  // max-height: 45vw;
  position: relative;
}

.hero2 {
  height: 100%;
  // background: rgb(21, 0, 36);
  background-image: url("/img/academy1.webp"),
    linear-gradient(285deg, $accent 63%, $primary 63.2%);
  // background-image: linear-gradient(285deg, #d9bad7 63%, $secondary 63.2%);
  background-repeat: no-repeat;
  background-position: bottom -40px right 40px, center;
  // background-position-y: bottom, center;
  // background-position-x: right;
  background-size: 80%, 100%;
}

.hero4 {
  background-image: url("/img/hero4.webp");
  height: 100%;
  min-height: 50vw;
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: bottom -10px right;
  // background-position-x: left;
}

.hero4a {
  background-image: url("/img/hero4.webp");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom -10px left -30px;
  opacity: 0.1;
  position: absolute;
  background-size: 100vw;
  bottom: 0;
  left: 0;
}

.hero-border {
  // border-bottom: $lilac 60px solid;
  background-color: $primary;
  // background: url("~@/assets/img/lilacborder.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  bottom: -30px;
  height: 60px;
  // left: -1px;
  width: 100%;
  transform: skew(0, -0.5deg);
  overflow: hidden;
}

.hero2a {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  // background-color: red;
  background-image: url("/img/academy1.webp");
  opacity: 0.1;
  background-repeat: no-repeat;
  // background-position: right top;
  background-position: right -150px bottom -40px !important;
  // background-position-x: right30%;
}

.fixme {
  top: 70px;
  position: fixed;
  z-index: 1000;
  border-bottom: 1px solid white;
}

.slantline {
  background-color: $secondary;
  border-top: dashed $accent 2px;
  transform: skew(0, -2.5deg) translateY(-60px);
}
</style>
