import { render, staticRenderFns } from "./Academy.vue?vue&type=template&id=a8806c8e&scoped=true&lang=pug&"
import script from "./Academy.vue?vue&type=script&lang=js&"
export * from "./Academy.vue?vue&type=script&lang=js&"
import style0 from "@platyplus/humanitarian-icons/dist/icons.css?vue&type=style&index=0&lang=css&"
import style1 from "./Academy.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./Academy.vue?vue&type=style&index=2&id=a8806c8e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8806c8e",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QVideo from 'quasar/src/components/video/QVideo.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';import Scroll from 'quasar/src/directives/Scroll.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QToolbar,QImg,QIcon,QChip,QVideo,QBtnDropdown,QList,QItem,QStepper,QStep});qInstall(component, 'directives', {Scroll});
